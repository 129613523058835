export const VServices = {
    state() {
        return {
            services: [],
        };
    },
    getters: {
        getAllServices(state) {
            return state.services;
        },
        getFeaturedServices(state) {
            return state.services.filter((service) => service.featured && !service.emphasized).slice(0, 4);
        },
        getEmphasizedServices(state) {
            return state.services.find((service) => service.emphasized);
        },
        getIsThereEmphasizedService(state) {
            return state.services.some((service) => service.emphasized);
        }
    },
    mutations: {
        setAllServices(state, services) {
            if(!services || services.length === 0) {
                console.log('[VServices] Test verisi oluşturuldu. [setAllServices]');

                state.services = [
                    {
                        id: 1,
                        name: "Yazılım Danışmanlığı",
                        shortDescription: "Yazılım danışmanlığı hizmeti",
                        description: "Frontend, backend, fullstack yazılım danışmanlığı hizmeti",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım danışmanlığı hizmeti</p>",
                        imageUrl: "assets/images/left-banner-image.jpg",
                        enabled: true,
                        featured: true,
                        emphasized: true,
                    },
                    {
                        id: 2,
                        name: "Yazılım Geliştirme",
                        shortDescription: "Yazılım geliştirme hizmeti",
                        description: "Frontend, backend, fullstack yazılım geliştirme hizmeti",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım geliştirme hizmeti</p>",
                        imageUrl: "assets/images/baner-right-image-01.jpg",
                        enabled: false,
                        featured: true,
                    },
                    {
                        id: 3,
                        name: "Yazılım Test",
                        shortDescription: "Yazılım test hizmeti",
                        description: "Frontend, backend, fullstack yazılım test hizmeti",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım test hizmeti</p>",
                        imageUrl: "assets/images/baner-right-image-02.jpg",
                        enabled: false,
                        featured: true,
                    },
                    {
                        id: 4,
                        name: "Yazılım Destek",
                        shortDescription: "Yazılım destek hizmeti",
                        description: "Frontend, backend, fullstack yazılım destek hizmeti",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım destek hizmeti</p>",
                        imageUrl: "assets/images/baner-right-image-03.jpg",
                        enabled: false,
                        featured: true,
                    },
                    {
                        id: 5,
                        name: "Yazılım Bakım",
                        shortDescription: "Yazılım bakım hizmeti",
                        description: "Frontend, backend, fullstack yazılım bakım hizmeti",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım bakım hizmeti</p>",
                        imageUrl: "assets/images/baner-right-image-04.jpg",
                        enabled: false,
                        featured: true,
                    },
                ];

                return;
            }

            state.services = services;
        },
    },
    actions: {
        async getAllServices({ commit }) {
            commit("setAllServices", []);

            return {
                isSuccess: true,
            };
        },
    },
};
