export const VInstagramPosts = {
    state() {
        return {
            posts: [],
        };
    },
    getters: {
        getInstagramPosts(state) {
            return state.posts;
        },
    },
    mutations: {
        setAllInstagramPosts(state, posts) {
            if(!posts || posts.length === 0) {
                console.log('[VInstagramPosts] Test verisi oluşturuldu. [setAllInstagramPosts]');

                state.posts = [
                    {
                        id: 1,
                        postUrl: "https://www.instagram.com/p/CS1J9J9J9J9/",
                        imageUrl: "assets/images/instagram-01.jpg",
                        tag: "Pro Mental",
                    },
                    {
                        id: 2,
                        postUrl: "https://www.instagram.com/p/CS1J9J9J9J9/",
                        imageUrl: "assets/images/instagram-02.jpg",
                        tag: "Beraber İnceleyelim",
                    },
                    {
                        id: 3,
                        postUrl: "https://www.instagram.com/p/CS1J9J9J9J9/",
                        imageUrl: "assets/images/instagram-03.jpg",
                        tag: "Web Paketi",
                    },
                    {
                        id: 4,
                        postUrl: "https://www.instagram.com/p/CS1J9J9J9J9/",
                        imageUrl: "assets/images/instagram-04.jpg",
                        tag: "Qr Menü",
                    },
                    {
                        id: 5,
                        postUrl: "https://www.instagram.com/p/CS1J9J9J9J9/",
                        imageUrl: "assets/images/instagram-05.jpg",
                        tag: "Mini Mental",
                    }
                    ,
                    {
                        id: 6,
                        postUrl: "https://www.instagram.com/p/CS1J9J9J9J9/",
                        imageUrl: "assets/images/instagram-06.jpg",
                        tag: "Kovan Takip Sistemi",
                    }
                ];

                return;
            }

            state.posts = posts;
        },
    },
    actions: {
        async getAllInstagramPosts({ commit }) {
            commit("setAllInstagramPosts", []);

            return {
                isSuccess: true,
            };
        },
    },
};
