<template>
    <!-- ***** Social Area Starts ***** -->
  <section class="section" id="social">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h2>Social Media</h2>
            <span v-html="getSocialMediaBannerDescriptionHtml"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row images">
        <a v-for="post in getInstagramPosts" :key="post.id" class="col-2" :title="post.tag" :href="post.postUrl" target="_blank">
          <div class="thumb">
            <div class="icon">
                <h6>{{ post.tag }}</h6>
                <i class="fa fa-instagram"></i>
            </div>
            <img :src="post.imageUrl" :alt="post.tag">
          </div>
        </a>
      </div>
    </div>
  </section>
  <!-- ***** Social Area Ends ***** -->
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "SocialArea",
    computed: {
        ...mapGetters({
            getSocialMediaBannerDescriptionHtml: "getSocialMediaBannerDescriptionHtml",
            getInstagramPosts: "getInstagramPosts",
        }),
    },
}
</script>