<template>
    <!-- ***** Explore Area Starts ***** -->
    <section class="section" id="explore">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="left-content" v-html="getShortAboutUsHtml"></div>
                </div>
                <div class="col-lg-6">
                    <div class="right-content">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="leather">
                                    <h4>{{ aboutUsText1 }}</h4>
                                    <span>{{ aboutUsText2 }}</span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="first-image">
                                    <img :src="aboutUsPhotoUrl1" alt="Virtüöz Software">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="second-image">
                                    <img :src="aboutUsPhotoUrl2" alt="Virtüöz Software">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="types">
                                    <h4>{{ aboutUsText3 }}</h4>
                                    <span>{{ aboutUsText4 }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ***** Explore Area Ends ***** -->
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "ExploreArea",
    data() {
        return {
            aboutUsText1: "",
            aboutUsText2: "",
            aboutUsText3: "",
            aboutUsText4: "",

            aboutUsPhotoUrl1: "",
            aboutUsPhotoUrl2: "",
        };
    },
    computed: {
        ...mapGetters({
            getShortAboutUsHtml: "getShortAboutUsHtml",
        }),
    },
    mounted(){
        this.getAboutUsTexts();
        this.getAboutUsPhotos();
    },
    methods:{
        ...mapActions({
            getTextByPrimaryKeys: "getTextByPrimaryKeys",
            getPhotoByPrimaryKeys: "getPhotoByPrimaryKeys",
        }),
        async getAboutUsTexts(){
            this.aboutUsText1 = await this.getTextByPrimaryKeys("homePageAboutUsText1");
            this.aboutUsText2 = await this.getTextByPrimaryKeys("homePageAboutUsText2");
            this.aboutUsText3 = await this.getTextByPrimaryKeys("homePageAboutUsText3");
            this.aboutUsText4 = await this.getTextByPrimaryKeys("homePageAboutUsText4");
        },
        async getAboutUsPhotos(){
            this.aboutUsPhotoUrl1 = await this.getPhotoByPrimaryKeys("homePageAboutUsPhoto1");
            this.aboutUsPhotoUrl2 = await this.getPhotoByPrimaryKeys("homePageAboutUsPhoto2");
        }
    },
}
</script>