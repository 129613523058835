export const VFixedPhotos = {
    state() {
        return {
            photos: [],
        };
    },
    getters: {
        // getPhoto(state) {
        //     return state.texts.find(x => x.primaryKey === 'shortAboutUsHtml')?.content;
        // },
    },
    mutations: {
        getAllFixedPhotos(state, photos) {
            if(!photos || photos.length === 0) {
                console.log('[VFixedPhotos] Test verisi oluşturuldu. [getAllFixedPhotos]');

                state.photos = [
                    {
                        id: 1,
                        primaryKey: "homePageAboutUsPhoto1",
                        imageUrl: "assets/images/explore-image-01.jpg"
                    },
                    {
                        id: 2,
                        primaryKey: "homePageAboutUsPhoto2",
                        imageUrl: "assets/images/explore-image-02.jpg"
                    },
                ];

                return;
            }

            state.photos = photos;
        },
    },
    actions: {
        async getAllFixedPhotos({ commit }) {
            commit("getAllFixedPhotos", []);

            return {
                isSuccess: true,
            };
        },
        async getPhotoByPrimaryKeys({ state }, primaryKey) {
            return state.photos.find(x => primaryKey === x.primaryKey).imageUrl;
        }
    },
};
