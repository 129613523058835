<template>
    <!-- Main Banner Area Start -->
    <div class="main-banner">
        <div class="container-fluid">
            <div class="row">
                <div v-if="isThereEmphasizedService" class="col-lg-6">
                    <div class="left-content">
                        <div class="thumb">
                            <div class="inner-content">
                                <h4>{{ getEmphasizedServices.name }}</h4>
                                <span>{{ getEmphasizedServices.shortDescription }}</span>
                                <div class="main-border-button">
                                    <router-link :to="'/service-detail/' + getEmphasizedServices.name ">İncele!</router-link>
                                </div>
                            </div>
                            <img :src="getEmphasizedServices.imageUrl" alt="">
                        </div>
                    </div>
                </div>
                <div :class="isThereEmphasizedService ? 'col-lg-6' : 'col-lg-12'">
                    <div class="right-content">
                        <div class="row">
                            <div v-for="feature in getFeaturedServices" :key="feature.id" :class="isThereEmphasizedService ? 'col-lg-6' : 'col-lg-3'">
                                <div class="right-first-image">
                                    <div class="thumb">
                                        <div class="inner-content">
                                            <h4>{{ feature.name }}</h4>
                                            <span>{{ feature.shortDescription }}</span>
                                        </div>
                                        <div class="hover-content">
                                            <div class="inner">
                                                <h4>{{ feature.name }}</h4>
                                                <p>{{ feature.description }}</p>
                                                <div class="main-border-button">
                                                    <router-link v-if="feature.enabled" :to="'/service-detail/' + feature.id ">İncele!</router-link>
                                                    <a v-else href="#top">Yakında!</a>
                                                </div>
                                            </div>
                                        </div>
                                        <img :src="feature.imageUrl">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Main Banner Area End -->
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "MainBanner",
    data() {
        return {
            isThereEmphasizedService: false,
        };
    },
    computed: {
        ...mapGetters({
            getFeaturedServices: "getFeaturedServices",
            getEmphasizedServices: "getEmphasizedServices",
            getIsThereEmphasizedService: "getIsThereEmphasizedService",
        }),
    },
    mounted(){
        this.getAllServices();

        this.initializeServices();
    },
    methods:{
        ...mapActions({
            getAllServices: "getAllServices",
        }),
        initializeServices(){
            this.isThereEmphasizedService = this.getIsThereEmphasizedService;
        }
    },
}
</script>