<template>
    <!-- Header -->
  <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">

            <!-- Logo Start -->
            <a href="index.html" class="logo">
              <img :src="baseUrl + 'assets/images/logo.png'">
            </a>
            <!-- Logo End -->

            <!-- Menu Start -->
            <ul class="nav">
              <li class="scroll-to-section">
                <router-link to="/">Anasayfa</router-link>
              </li>
              <li class="scroll-to-section"><a href="#men">Men's</a></li>
              <li class="scroll-to-section"><a href="#women">Women's</a></li>
              <li class="scroll-to-section"><a href="#kids">Kid's</a></li>
              <li class="submenu">
                <a href="javascript:;">Kurumsal</a>
                <ul>
                  <li><a href="about.html">Hakkımızda</a></li>
                  <li><a href="contact.html">İletişim</a></li>
                </ul>
              </li>
              <li class="scroll-to-section">
                <a href="#explore">Virtüöz</a>
              </li>
            </ul>
            <a class='menu-trigger'>
              <span>Menu</span>
            </a>
            <!-- Menu End -->
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!-- Header End -->
</template>
<script>
export default {
  name: "App-Header",
  data() {
    return {
      baseUrl: window.location.origin + '/',
    };
  },
};
</script>